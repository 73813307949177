<script>
import { required } from "vuelidate/lib/validators";





import PatientService from "@/services/patient-service";


/**
 * Patients Component
 */
export default {
  components: {

    

  },
  data() {
    return {
      patientId: null,
      title: this.$t("all.patients"),
      items: [
        {
          text: this.$t("all.admin"),
        },
        {
          text: this.$t("all.patients"),
          active: true,
        },
      ],
      patient: {
        address: {
          address: null,
          complement: null,
        },
        gender: null,
      },
      submitted: false,
      paymentTypes: [],
      genders: []
    }
  },

  validations: {
    patient: {
      name: { required },
      birth: { required },
      gender: { required },
      paymentType: { required },
    },
  },
  updated() {
    window.print();


  },

  created() {
    this.loading = true;
    this.service = new PatientService(this.$http);

    this.patientId = this.$route.params.id
    if (this.patientId) {
      this.charge(this.patientId);
    }
    this.loading = false;
  },
  methods: {
    formatDate(date) {
      const d = new Date(date),
        day = ('0' + d.getDate()).slice(-2),
        month = ('0' + (d.getMonth() + 1)).slice(-2),
        year = d.getFullYear();
      return `${day}/${month}/${year}`;
    },
    age(birthDate, full) {
      let now = new Date();
      birthDate = new Date(Date.parse(birthDate));
      var years = now.getFullYear() - birthDate.getFullYear();
      var months = now.getMonth() - birthDate.getMonth();
      var days = now.getDate() - birthDate.getDate();
      if (months < 0 || (months == 0 && days < 0)) {
        years--;
        months += days < 0 ? 11 : 12;
      }

      if (days < 0) {
        let monthAgo = new Date(
          now.getFullYear(),
          now.getMonth() - 1,
          birthDate.getDate()
        );
        days = Math.floor((now - monthAgo) / (1000 * 60 * 60 * 24));
      }
      if (full) {
        return years + " anos e " + months + " meses";
      }
      return years + "a" + months + "m" + days + "d";
    },
    charge(id) {
      this.loading = true;
      this.service.get(id).then((res) => {
        console.log(res);
        this.patient = res.data;
        let date = new Date(Date.parse(this.patient.birth));
        this.patient.birth = date;
        console.log(this.patient)
        if (!this.patient.address) {
          this.patient.address = {
            address: ""
          }
        }
        this.loading = false;
      }),
        (err) => {
          console.error(err);
        };
    },



    cancel() {
      this.returnToPatient();
    },
    returnToPatient() {
      this.$router.push(`/admin/patient`);
    },

  },

};
</script>

<template>
  <div>
    


    <div class="row col-lg-12">

      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div>
              <div v-if="loading" class="d-flex justify-content-center"></div>
            </div>

            <div class="row">
              <div class="col-print-2 col-print-2">Nome</div>
              <div class="col-print-4 col-print-4">{{ patient.name }}</div>
              <div class="col-print-2 col-print-2">Idade</div>
              <div class="col-print-4 col-print-4">{{ age(patient.birth, true) }} </div>
            </div>

            <div class="row alert-secondary">
              <div class="col-print-2">Genero</div>
              <div class="col-print-4">{{ patient.gender.name }}</div>
              <div class="col-print-2">Data Nascimento</div>
              <div class="col-print-4">{{ formatDate(patient.birth) }} </div>
            </div>
            <div class="row ">
              <div class="col-print-2">CPF</div>
              <div class="col-print-4">{{ patient.cpf }}</div>
              <div class="col-print-2">RG</div>
              <div class="col-print-4">{{ patient.rg }} </div>
            </div>

            <div class="row alert-secondary">
              <div class="col-print-2">Forma de Pagamento</div>
              <div class="col-print-4">{{ patient.paymentType.name }}</div>
            </div>

            <div class="row">
              <div class="col-print-2">Rua </div>
              <div class="col-print-4">{{ patient.address.address }}</div>
              <div class="col-print-2"></div>
              <div class="col-print-4">{{ patient.address.addressNumber }} </div>
            </div>

            <div class="row alert-secondary">
              <div class="col-print-2">Complemento</div>
              <div class="col-print-4">{{ patient.address.complement }}</div>
              <div class="col-print-2">CEP</div>
              <div class="col-print-4">{{ patient.address.zipCode }} </div>
            </div>

            <div class="row">
              <div class="col-print-2">Bairro</div>
              <div class="col-print-4">{{ patient.address.district }}</div>

            </div>
            <div class="row alert-secondary">
              <div class="col-print-2">Cidade</div>
              <div class="col-print-4">{{ patient.address.city }}</div>
              <div class="col-print-2">Estado</div>
              <div class="col-print-4">{{ patient.address.state }} </div>
            </div>



            <hr/>


            <div class="row">
              <div class="col-print-2">Observações</div>
              <div class="col-print-10">
                <textarea rows="10" v-model="patient.anotations" class="col-print-12">

                </textarea>
              </div>

            <hr size="2"/>
            </div>
            <div class="row alert-secondary">
              <div class="col-print-2">Diagnóstico</div>
              <div class="col-print-10">
                <textarea rows="10" v-model="patient.diagnosis" class="col-print-12">
              </textarea>
              </div>

            </div>
            <!--Endereço -->


           
          </div>
        </div>
      </div>

    </div>

  </div>
</template>
<style scoped>


.col-print-1 {width:8%;  float:left;}
.col-print-2 {width:16%; float:left;}
.col-print-3 {width:25%; float:left;}
.col-print-4 {width:33%; float:left;}
.col-print-5 {width:42%; float:left;}
.col-print-6 {width:50%; float:left;}
.col-print-7 {width:58%; float:left;}
.col-print-8 {width:66%; float:left;}
.col-print-9 {width:75%; float:left;}
.col-print-10{width:83%; float:left;}
.col-print-11{width:92%; float:left;}
.col-print-12{width:100%; float:left;}

</style>